import { Ref } from 'vue'
import type { Store, TemplateVersion, Template, TemplateLayout } from '../builder'
import templateService from '../../../services/TemplateService'
import { loadAssetFromUrl } from '../lib/assetManagement'
import { objectEqual } from '../lib/utils'
import { useRoute } from 'vue-router'

export const formatTemplateToStore = async (template: Template): Promise<TemplateLayout> => {
  const assets = template.layout?.theme?.assets
    ? await Promise.all(template.layout.theme.assets?.map(async asset => loadAssetFromUrl(asset)))
    : undefined
  return {
    id: template.id,
    name: template.name,
    ...template.layout,
    theme: {
      ...template.layout.theme,
      assets,
    },
  }
}

export default function useVersioning(store: Store, templateRef: Ref<TemplateLayout>, profile: any) {
  const route = useRoute()
  async function saveVersion(dataReportId: string, dataQualityCheckReportId: Number | null) {
    if (store.versions.at(-1)?.readOnly) return
    if (objectEqual(store.versions.at(-1)?.layout, templateRef.value)) return console.log('skip save')
    const version: TemplateVersion = {
      name: store.versions.at(-1)?.name,
      createdAt: new Date().toISOString(),
      user: profile.email || profile['x-hasura-user-id'],
      layout: JSON.parse(JSON.stringify(templateRef.value)),
    }
    const theme = {
      ...version.layout.theme,
      assets: version.layout.theme.assets?.map((asset: any) => {
        if (asset.bucketPath) {
          return {
            ...asset,
            src: undefined,
          }
        }
        return asset
      }),
    }
    if (theme.loadedStylesheets) {
      delete theme.loadedStylesheets
    }
    const _dataReportId = isNaN(parseInt(dataReportId)) ? null : parseInt(dataReportId)
    const layout: TemplateLayout = {
      ...version.layout,
      id: undefined,
      name: undefined,
      theme,
    }
    const newTemplate = await templateService.create({
      templateInput: {
        name: version.name,
        layout: {
          ...layout,
          nodes: layout.nodes.filter((node: any) => !node.isVirtual),
          dataReportId: _dataReportId,
        },
        type: 'pdf',
        dataReportId: _dataReportId,
        dataQualityCheckReportId: dataQualityCheckReportId || null,
      },
    })

    version.id = newTemplate.id.toString()
    store.versions = [version].concat(store.versions)
    store.undo = [version.layout]
    store.redo = []
    store.undo_skip = true
  }
  const deleteVersion = async (version: any) => {
    if (store.versions.length === 1) return
    store.versions = store.versions.filter(v => v !== version)
    await templateService.deleteOne({ id: parseInt(version.id) })
  }
  const restoreVersion = async (version: any) => {
    if (objectEqual(version.layout, templateRef.value)) return
    templateRef.value = await formatTemplateToStore(version)
  }
  const downloadVersion = () => {
    // @ts-expect-error string prototype extended
    JSON.stringify(templateRef.value).download(
      // @ts-expect-error Date prototype extended
      `${route.query.templateName}-${new Date().format('YYYY-MM-DD.hhhmmm')}.json`,
    )
  }
  const uploadVersion = (ev: Event) => {
    if (!ev.target) return
    const file = (ev.target as HTMLInputElement).files?.[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = e => {
      if (!e?.target?.result) return
      templateRef.value = JSON.parse(e.target.result as string)
    }
    reader.readAsText(file)
  }
  return { saveVersion, deleteVersion, restoreVersion, downloadVersion, uploadVersion }
}
